import React from "react"

const SummaryBox = ({ summaryText = "Summary text goes here" }) => {
  return (
    <section className=" flex flex-col justify-center md:-mt-12 mt-5 md:mt-0 bg-gray-dark max-w-4xl mx-auto px-5 sm:px-16 relative">
      <p className="text-justify text-xl w-full leading-snug my-5">
        {summaryText}
      </p>
    </section>
  )
}

export default SummaryBox
