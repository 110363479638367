import React from "react"
import { graphql } from "gatsby"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/Hero"
import PageSection from "../components/global/PageSection"
import BlockContentPrimary from "../components/blockContentPrimary"
import SummaryBox from "../components/pagePieces/SummaryBox"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import { sanityConfig } from "../sanityConfig"

const Diabetes = ({ data }) => {
  const { sanityDiabetesPage } = data
  const { englishTitle, mainContent } = sanityDiabetesPage
  const { _rawHeroImage: rawHeroImage } = mainContent

  let hero = {}
  if (rawHeroImage) {
    // if a hero image was provided by the user,
    // create a fluid property because Hero component expects it
    hero.fluid =
      getFluidGatsbyImage(
        rawHeroImage?.asset?._id,
        { maxWidth: 1200 },
        sanityConfig
      ) || null
  } else {
    hero = null
  }

  return (
    <Layout>
      <SEO title="Curriculum" />
      <Hero englishTitle={englishTitle} heroImage={hero} />
      {mainContent.summary && <SummaryBox summaryText={mainContent.summary} />}
      <PageSection
        className=""
        innerContent={
          <div className="text-primary-dark max-w-6xl">
            <BlockContentPrimary
              blockData={sanityDiabetesPage.mainContent._rawMainContent}
            />
          </div>
        }
      ></PageSection>
    </Layout>
  )
}

export const query = graphql`
  query DiabetesQuery {
    sanityDiabetesPage {
      englishTitle
      mainContent {
        syllabicsTitle
        summary
        _rawMainContent(resolveReferences: { maxDepth: 10 })
        _rawHeroImage(resolveReferences: { maxDepth: 10 })
        heroImage {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`

export default Diabetes
